import { ref } from '@vue/composition-api'

export default {
  name: 'disabledDevice',
  props: {
    keyName: String,
  },
  setup(props, { emit }) {
    const listCheckBox = ref([
      // { title: 'Настроить пароль для доступа к системным файлам', description: 'Пароль - это важный элемент цифровой защиты, который не дает сторонним приложениям или пользователям вмешиваться в работу системных файлов, а значит, и в работу всей ОС.', active: false },
      { title: 'Разрешить установку программ только для учётной записи Администратора', description: 'Под учетной записью Администратора запрашивается специальный пароль. Это дополнительный уровень защиты, который не позволит злоумышленникам установить вредоносную программу.', active: false },
      { title: 'Делать резервные копии файлов', description: 'Делать резервные (запасные) копии важных файлов необходимо, тогда в случае атаки их можно будет восстановить.', active: false },
    ])

    let fired = false

    const getObj = (val) => {
      if (val.switcher && !fired) {
        fired = true
        emit('message')
      }
      if (val.checkBox.every(e => e.active) && val.switcher) {
        emit('valid', true)
      } else {
        emit('valid', false)
      }
    }

    // const getObj = (val) => {
    //   if (!val.checkBox.some(e => e.active) && val.switcher) {
    //     emit('valid', false)
    //   } else {
    //     emit('valid', true)
    //   }
    // }

    return {
      listCheckBox,
      getObj,
    }
  },
}
