import containerUpdate from '@/components/@ui/containerUpdate'

export default {
  name: 'updateAntivirus',
  props: {
    keyName: String,
  },
  components: {
    containerUpdate,
  },
  setup() {
    return {}
  },
}
