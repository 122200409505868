<template>
  <div>
    <l-slider :items="comics" @finish="$emit('nextStep', 1)" />
  </div>
</template>
<script>
const comics = [
  { url: require('@/assets/lesson1/scene7_1.svg') },
  { url: require('@/assets/lesson1/scene8_1.svg') },
  { url: require('@/assets/lesson1/scene8_2.svg') },
  { url: require('@/assets/lesson1/scene8_3.svg') },
]

export default {
  setup() {
    return {
      comics,
    }
  },
}
</script>
