import { ref } from '@vue/composition-api'

export default {
  name: 'updateSystem',
  props: {
    keyName: String,
  },
  setup(props, { emit }) {
    const listCheckBox = ref([
      { title: 'Программы с флешки', active: true },
      { title: 'Скаченные файлы', active: true },
      { title: 'При подключении нового устройства', active: true },
    ])

    const programsList = ref([
      { title: 'Антивирус', required: true, active: false },
      { title: 'Обновление системы', required: true, active: false },
      { title: 'Офисные программы', required: false, active: Math.random() > 0.5 },
      { title: 'Пасьянс «Косынка»', required: false, active: Math.random() > 0.5 },
      { title: 'Виджет погоды', required: false, active: Math.random() > 0.5 },
    ].sort(() => 0.5 - Math.random()))

    const getObj = (val) => {
      const checkboxes = val.checkBox.every(e => !e.active)
      const programs = val.program.filter(e => e.required).every(e => e.active)

      emit('valid', checkboxes && programs)
    }
    return {
      listCheckBox,
      programsList,
      getObj,
    }
  },
}
