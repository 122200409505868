export const popupsArr = [
  { text: 'Отлично! Ты устранил все уязвимости. Вероятнее всего, вредоносный код смог запустится на компьютере благодаря неисправленной ошибке в операционной системе!', buttonTitle: 'ПРОДОЛЖИТЬ' },
]
export const popupsArrErr = [
  { text: 'Ошибка! Внимательнее проверь настройки.', errSuccess: true },
]

export const arrSecurityScanner = () => ([
  {
    warning: true, btnText: 'Критичная', text: 'Обновление операционной системы', svg: 'plus', key: 'updateSystem', update: false,
  },
  {
    warning: true, btnText: 'Критичная', text: 'Обновление антивирусных баз', svg: 'plus', key: 'updateAntivirus', update: false,
  },
  {
    warning: true, btnText: 'Критичная', text: 'Обновление офисных программ', svg: 'plus', key: 'updateOffice', update: false,
  },
  {
    warning: true, btnText: 'Критичная', text: 'Настроить систему', svg: 'plus', key: 'disabledDevice', update: false,
  },
  {
    warning: true, btnText: 'Критичная', text: 'Настроить автозапуск', svg: 'plus', key: 'updateAutostart', update: false,
  },
  {
    recommend: true, btnText: 'Рекомендация', text: 'Обновление Game Rstars', svg: 'plus', key: 'wrong1',
  },
  {
    recommend: true, btnText: 'Рекомендация', text: 'Обновление программы Погода', svg: 'plus', key: 'wrong2',
  },
  {
    recommend: true, btnText: 'Рекомендация', text: 'Обновление программы Калькулятор', svg: 'plus', key: 'wrong3',
  },
  {
    recommend: true, btnText: 'Рекомендация', text: 'Обновление программы Фото', svg: 'plus', key: 'wrong4',
  },
].sort(() => 0.5 - Math.random()))

export const successArr = {
  updateSystem: { text: 'Верно! Обновлять операционную систему необходимо, так как с обновлением могут появляться не только новые функции, но и «заплатки» безопасности, в которых устраняют <span class="tooltip"><b>уязвимости</b><span class="tooltip__content">Уязвимости в программах и операционных системах — ошибки в коде, допущенные на каком-то из этапов разработки. Они позволяют злоумышленникам запустить на устройстве некоторые вредоносные программы.\n\n Вот почему важна своевременная установка обновлений: с их помощью разработчики исправляют ошибки в коде программ и закрывают уязвимости.</span></span>.' },
  updateAntivirus: { text: 'Точно! Каждый антивирус имеет базу вредоносного программного обеспечения. Важно поддерживать базу в актуальном состоянии.' },
  updateOffice: { text: 'Правильно! Если в офисном приложении не исправлена уязвимость, то хакер может активировать вирус, используя уязвимость в таких программах и других подобных.' },
  updateAutostart: { text: 'Верно! Программы в автозагрузке — это ПО, которое автоматически запускается при старте операционной системы и может служить для самых разных целей: антивирусные программы, новостные ленты, погода, мессенджеры, сервисы облачного хранения данных, — обычно вы можете видеть их значки в области уведомлений справа внизу. Однако, точно также в автозагрузку могут добавляться и вредоносные программы. Поэтому важно проверить, для каких программ у вас настроен автозапуск, и оставить только те, что действительно нужны и не причинят вреда системе.' },
  disabledDevice: { text: 'Правильно! Удаленный доступ — это специальная функция, которая позволяет подключиться к компьютеру удаленно. Хакеры могут воспользоваться данной функцией для кражи важных данных, поэтому лучше отключить эту функцию.' },
}
